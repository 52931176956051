<template>
  <div id="Blog">
    <HeaderMenu />
    <div class="blog-cont">
      <div class="header-c pc" :style="{'background-image':'url('+isWebp(require('../assets/blog-h-pc.webp'), require('../assets/blog-h-pc.png'))+')'}"></div>
      <div class="header-c mo" :style="{'background-image':'url('+isWebp(require('../assets/blog-h-mo.webp'), require('../assets/blog-h-mo.png'))+')'}"></div>
      <div class="blog-c">
        <section class="b-n-sec">
          <div class="blog-nav mo">
            <div
              class="b-n-select pointer"
              @click="menul == false ? (menul = true) : (menul = false)"
            >
              <p>{{ catselect }}</p>
              <img :src="isWebp(regexImg('../assets/flecha2.svg'), '../assets/flecha2.svg')" alt="flecha" class="flecha" />
            </div>
            <div class="nav-items" v-if="menul == true" @click="menul = false">
              <div class="nav-op pointer" @click="catselect = 'TODO'">
                <p>TODO</p>
              </div>
              <div
                class="nav-op pointer"
                v-for="(c, i) in categorias"
                :key="i"
                @click="catselect = c.name"
              >
                <p>{{ c.name }}</p>
              </div>
            </div>
          </div>
          <div class="blog-nav pc">
            <div class="nav-items">
              <div class="nav-op pointer TODO" @click="catselect = 'TODO'"
                :class="{'active':'TODO' == catselect}">
                <p>TODO</p>
              </div>
              <div
                class="nav-op pointer"
                v-for="(c, i) in categorias"
                :key="i"
                @click="catselect = c.name"
                :class="c.name && {'active':c.name == catselect}"
              >
                <p>{{ c.name }}</p>
              </div>
            </div>
          </div>
        </section>
        <div class="two-items">
          <section class="b-l-sec" v-if="blog.length == 0 || blog == 'error'">Sección no disponible</section>
          <section class="b-l-sec" v-else>
            <div class="blog-list-cont mo">
              <a
                v-for="(b, i) in arraypaginate"
                :key="i"
                :href="'/blog/' + b.url"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
              >
                <div class="blog-item">
                  <img :src="srcImg(b.image)" alt="" class="blog-i" />
                  <div class="bi-info">
                    <p class="cat">{{ b.category_name }}</p>
                    <p class="titulo-b">{{ b.title }}</p>
                    <a :href="'/blog/' + b.url" class="read-more"
                      >Leer más >
                    </a>
                  </div>
                </div>
              </a>
              <div class="pagination" v-if="blog.length > 3">
                <img
                  src="../assets/prev-blog.png"
                  alt="prev"
                  @click="pageNum -= 1"
                  class="pointer"
                  :class="{ disabled: pageNum == 1 }"
                />
                <img
                  src="../assets/next-blog.png"
                  alt="next"
                  @click="pageNum += 1"
                  class="pointer"
                  :class="{ disabled: pageNum == totalPages }"
                />
              </div>
            </div>
            <div class="blog-list-cont pc">
              <a v-for="(b, i) in blog" :key="i" :href="'/blog/' + b.url"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700">
                <div class="blog-item">
                  <img :src="srcImg(b.image)" alt="" class="blog-i" />
                  <div class="bi-info">
                    <p class="cat">{{ b.category_name }}</p>
                    <p class="titulo-b">{{ b.title }}</p>
                    <a :href="'/blog/' + b.url" class="read-more"
                      >Leer más >
                    </a>
                  </div>
                </div>
              </a>
            </div>
          </section>
          <section class="widget-sec">
            <WidgetBlog />
          </section>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import HeaderMenu from "../components/fixedItems/HeaderMenu.vue";
import Footer from "../components/fixedItems/Footer.vue";
import WidgetBlog from "../components/fixedItems/widgetBlog.vue";
let urlpath = require("../global/url");
export default {
  name: "Home",
  components: {
    HeaderMenu,
    Footer,
    WidgetBlog,
  },
  data() {
    return {
      menul: false,
      catselect: "TODO",
      urlpath: urlpath,
      pageNum: 1,
      pageSize: 3,
    };
  },
      metaInfo(){
        return{
            title: "Blog",
            titleTemplate: "%s | Agriga de México",
            htmlAttrs: {
            lang: "es",
            },
            meta: [
            { charset: "utf-8" },
            {
                name: "description",
                content: "Si te interesa la salud y el cuidado de tus animales, este es el lugar indicado. Aprende de la mano de nuestros expertos.",
            },
            { name: "viewport", content: "width=device-width, initial-scale=1" },
            ],            
        };
      },
  async created() {
    await this.getAllInfoBlo("blog");
    await this.getAllInfoCat("category");
    /*let payload = {
      option:'blog',
      search:'',
    }
    await this.searchBlo(payload);*/
  },
  computed: {
    blog() {
      let array = this.$store.getters["blog/data"];
      let arr = [];
      if(array == 'error'){
        return 'error'
      }else{
        if (this.catselect == "TODO") {
          return array;
        } else {
          for (let i = 0; i < array.length; i++) {
            if (array[i].category_name == this.catselect) {
              arr.push(array[i]);
            }
            let arr2 = array[i].blog_categories;
            for (let j = 0; j < arr2.length; j++) {
              if (arr2[j].category_name == this.catselect) {
                arr.push(array[i]);
              }
            }
          }
          return arr;
        }        
      }
    },
    arraypaginate() {
      let array = this.blog;
      let arr = array.slice(
        (this.pageNum - 1) * this.pageSize,
        this.pageNum * this.pageSize
      );
      return arr;
    },
    totalPages() {
      let total = Math.ceil(this.blog.length / this.pageSize);
      if (total < this.pageNum) {
        this.pageNum = 1;
      }
      return total;
    },
    categorias() {
      let array = this.$store.getters["category/data"];
      let arr = [];
      for (let i = 0; i < array.length; i++) {
        if (array[i].parent_name == "BLOG") {
          arr.push(array[i]);
        }
      }
      return arr;
    },
  },
  methods: {
    ...mapActions("blog", ["getAllInfoBlo"]),
    /*...mapActions("blog", ["searchBlo"]),*/
    ...mapActions("category", ["getAllInfoCat"]), 

    regexImg(txt) {
      let s = txt.split(".");
      return s[0] + ".webp";
    },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return webpImg; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return webpImg; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return webpImg; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return pngImg; // Safari
      }
    },
    srcImg: function (img) {
      let src = `${urlpath.url()}/P6H_3Pm/bt7-EO4/blog-img/${this.isWebp(this.regexImg(img),img)}`; 
      return src;
    }, 
  },
};
</script>
<style>
.blog-nav.mo {
  display: block;
}
.blog-nav.pc {
  display: none;
}
.header-c {
  width: 100%;
  height: 48.832vw;
  background-image: url("../assets/blog-h-mo.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
.blog-c {
  padding: 7.71vw 11.215vw;
}
.b-n-select {
  width: 44.159vw;
  background: #3ebdb1;
  margin: auto;
  padding: 5.374vw 6.308vw 5.607vw 7.71vw;
  display: flex;
  justify-content: space-between;
}
.b-n-select p {
  margin: 0;
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 4.206vw;
  line-height: 129.5%;
  color: #ffffff;
}
.b-n-select .flecha {
  width: 5.14vw;
}
.nav-op {
  width: 44.159vw;
  background: #ededd3;
  margin: 2.804vw auto 0;
  padding: 5.374vw 6.308vw 5.607vw 7.71vw;
}
.nav-op p {
  margin: 0;
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 4.206vw;
  line-height: 129.5%;
  color: #c5c597;
}
.two-items {
  padding-top: 13.785vw;
}
.blog-i {
  width: 100%;
  height: 58.879vw;
  object-fit: cover;
}
.blog-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 5.374vw;
}
.blog-list-cont.mo {
  display: block;
}
.blog-list-cont.pc {
  display: none;
}
.blog-list-cont a {
  text-decoration-color: transparent;
}
.bi-info {
  filter: drop-shadow(0px 0.701vw 1.168vw gray);
  border-radius: 0 0 4vw 4vw;
  background-color: white;
  padding: 4.206vw 4.907vw;
}
.bi-info p {
  margin: 0;
}
.bi-info .cat {
  font-family: "Helvetica World Regular";
  font-size: 3.271vw;
  line-height: 4.673vw;
  color: #3d9635;
}
.bi-info .titulo-b {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 5.841vw;
  line-height: 7.009vw;
  color: #0a2b12;
  margin: 1.402vw 0;
}
.bi-info a.read-more {
  font-family: "Helvetica World Regular";
  font-size: 3.271vw;
  line-height: 4.673vw;
  color: #fe9128;
  display: flex;
  justify-content: flex-end;
}
section.b-l-sec {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 5.841vw;
  line-height: 7.009vw;
  color: #0a2b12;
  padding-bottom: 20vw;
}
.pagination {
  width: 37.383vw;
  margin: 13.785vw auto 0;
  display: flex;
  justify-content: space-between;
}
.pagination img {
  width: 14.72vw;
  height: 14.72vw;
}
.pagination img.disabled {
  opacity: 0.5;
  pointer-events: none;
}
@media (max-width: 768px) {
  
.header-c.pc {
  display: none;
}
.header-c.mo {
  display: block;
  }
}
@media (min-width: 768px) {
.header-c.pc {
  display: block;
}
.header-c.mo {
  display: none;
  }
  .header-c {
    width: 100%;
    height: 18.385vw;
    background-image: url("../assets/blog-h-pc.png");
  }
  .blog-c {
    padding: 3.75vw 13.698vw;
  }
  .blog-nav.mo {
    display: none;
  }
  .blog-nav.pc {
    display: block;
  }
  .nav-items {
    display: flex;
    width: fit-content;
    margin: auto;
  }
  .nav-op {
    width: 7.813vw;
    height: 2.552vw;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.521vw;
  }
  .nav-op p {
    font-size: 0.729vw;
    line-height: 129.5%;
  }
  .two-items {
    padding-top: 6.354vw;
    display: flex;
    justify-content: space-between;
  }
  .blog-item {
    width: 22.552vw;
    transition: .5s;
  }
  .blog-item:hover {
    transform: scale(1.05);
  }
  .blog-i {
    height: 13.125vw;
  }
  .bi-info {
    padding: 1.458vw 2.24vw;
    border-radius: 0 0 1vw 1vw;
    filter: drop-shadow(0px 0.31vw 0.168vw gray);
  }
  .bi-info .cat {
    font-size: 0.729vw;
    line-height: 1.042vw;
  }
  .bi-info .titulo-b {
    font-size: 1.563vw;
    line-height: 1.875vw;
    margin: 0.156vw 0;
    min-height: 4vw;
  }
  .bi-info a.read-more {
    font-size: 0.729vw;
    line-height: 1.042vw;
    justify-content: flex-start;
  }
  .blog-list-cont {
    flex-wrap: wrap;
    width: 47.292vw;
    justify-content: space-between;
  }
  .blog-list-cont.mo {
    display: none;
  }
  .blog-list-cont.pc {
    display: flex;
  }
  .nav-op, .nav-op p {
    transition: .5s;
  }
  .nav-op.active, .nav-op:hover {
    background-color: #3EBDB1;
}
  .nav-op.active p, .nav-op:hover p {
    color: white;
}
  section.b-l-sec {
    padding-bottom: 0;
}
section.b-l-sec {
    font-size: 1.841vw;
    line-height: 2.009vw;}
}
</style>